// Initialisations
$(function() {
  if(window.location.hash) {
    if (window.location.hash.substring(1) == 'thanks') {
      $('form#contact p.message.success').show();
    }
  }

  if (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || navigator.userAgent.indexOf("Trident/")){
    // if ($('form').length() >= 1 ) {
      $('input, textarea').placeholder();
    // });
  }

  // Tab Menu
  $('#tenants-fees').hide();

  $('fees-expenses-menu a').on('click', function(event){

        event.preventDefault();
        $('fees-expenses-menu a').removeClass('active');
        $(this).addClass('active');

        var href = $(this).attr('href'); // Eg: #causes

        $('fees-expenses-section').hide();
        $('fees-expenses-section' + href).show();
    });

});
